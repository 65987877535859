import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { AttendeeModel } from '../models/attendee.model';
import { getDurationText } from '../services/time-range';
import Button from './Button';

type Props = {
    attendance_id: number;
    attendee: AttendeeModel
    refresh: () => void;
}

function TicketItem({ attendance_id, attendee, refresh }: Props) {
    const [dropin, duration_text] = React.useMemo(() => {
        const dropin = attendee.dropins && attendee.dropins.length > 0 ?
            attendee.dropins[0] : undefined;
        if (!!dropin?.checkin_at) {
            return [dropin, moment(dropin?.checkin_at).format('hh:mm a')]
        }
        return [dropin, 'Not Checked In']
    }, [attendee.dropins])

    const [execute, result] = useMutation(gql`
        mutation do_self_dropin($object: do_dropin_input!) {
            do_self_dropin(object: $object) {
                checkin_at
                checkout_at
            }
        }
    `)

    const checkin = (id: number) => async () => {
        try {
            await execute({
                variables: {
                    object: {
                        attendance_id,
                        attendee_id: id,
                    }
                }
            })
            refresh();
        } catch (e) {

        }
    }

    return (
        <div key={attendee.id} className='flex border-b-2 py-2 items-center'>
            <div className='flex-1 pr-2'>
                <p className='text-md font-bold'>{attendee.name}</p>
                <p className='text-sm'>{attendee.ticket.name}</p>
            </div>
            {dropin ?
                <div className='text-right'>
                    <p className='text-xs'>Checked In</p>
                    <p className='text-lg font-bold'>{duration_text}</p>
                </div>
                : <Button
                    loading={result.loading}
                    disabled={!!dropin}
                    onClick={checkin(attendee.id)} label='Check In' />}
        </div>
    )
}

export default TicketItem