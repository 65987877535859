import qs from 'query-string';
import React, { useMemo } from 'react';
import { ProvideApollo } from './apollo/with-apollo';
import Main from './screens/Main';
import { ProvideDialog } from './services/dialog.context';

function App() {
  const data = qs.parse(window.location.search);
  const [event_id, attendance_id] = useMemo(() => {
    if (!data || typeof(data.eid) !== 'string' || typeof(data.aid) !== 'string') {
      return [];
    }
    return [+data.eid, +data.aid];
  }, [data])

  return (
    <ProvideDialog>
      <ProvideApollo>
        {!event_id || !attendance_id ? <p>Missing event/attendance id. Check Url</p> : <Main 
        eventId={event_id}
        attendanceId={attendance_id} />}
      </ProvideApollo>
    </ProvideDialog>

  );
}

export default App;


