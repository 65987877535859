import { gql, useQuery } from '@apollo/client';
import React from 'react';
import * as yup from "yup";
import ListTicketsByEmail from '../components/ListTicketsByEmail';
import LoadingScreen from '../components/LoadingScreen';
import { EventFragment, EventModel } from '../models/event.model';

type LoginFields = {
    email: string;
}

const schema = yup.object({
    email: yup.string().required('Please enter your email'),
}).required();

type Props = {
    eventId: number;
    attendanceId: number;
}

function Main({ eventId, attendanceId }: Props) {
    const { data, loading } = useQuery(gql`
        query event($id: bigint!) {
            event_by_pk(id: $id) {
                ${EventFragment}
            }
        }
    `, {
        variables: {
            id: eventId,
        }
    })

    const event = data?.event_by_pk as EventModel;

    if (loading) {
        return <LoadingScreen />
    }

    if (!event) {
        return <p>Event not found</p>
    }

    return (
        <div className="container">
            <ListTicketsByEmail event={event} attendanceId={attendanceId} />
        </div>
    )
}


export default Main