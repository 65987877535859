import React from 'react'
import { EventModel } from '../models/event.model'
import LazyImage from './LazyImage';

export type EventHeaderProps = {
    event: EventModel;
}

function EventHeader({ event: item }: EventHeaderProps) {
    const feature_image = React.useMemo(() => {
        return item.feature_images && item.feature_images.length > 0
            ? item.feature_images[0] : undefined;
    }, [item.feature_images]);
    return (
        <div className='w-full rounded-lg overflow-hidden'>
            {feature_image ? <div className=''>
                <LazyImage
                    image_id={feature_image}
                    transformations={{
                        edits: {
                            rotate: null,
                            resize: {
                                width: 1200,
                            }
                        }
                    }}
                />
            </div> : null}
        </div>
    )
}

export default EventHeader