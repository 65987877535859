import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import React from 'react';
import LoadingScreen from '../components/LoadingScreen';
import environment from '../environment';

type Props = {
	children: React.ReactNode;
}

export const ProvideApollo = ({ children }: Props) => {
	const client = React.useMemo(() => {
		return initApolloClient();
	}, []);


	if (!client) {
		return <LoadingScreen />
	}

	return <ApolloProvider client={client}>
		{children}
	</ApolloProvider>
}

const initApolloClient = () => {
	const httpLink = new HttpLink({
		uri: environment.backend_url,
	});

	return new ApolloClient({
		link: from([httpLink]),
		connectToDevTools: !environment.production,
		cache: new InMemoryCache({}),
	});
}
