import { gql, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from "yup";
import { AttendeeModel } from '../models/attendee.model';
import { EventModel } from '../models/event.model';
import Button from './Button';
import EventHeader from './EventHeader';
import TextInput from './form/TextInput';
import Loading from './Loading';
import TicketItem from './TicketItem';

type LoginFields = {
    email: string;
}

const schema = yup.object({
    email: yup.string().email('Invalid email address').required('Please enter your email'),
}).required();

type Props = {
    event: EventModel;
    attendanceId: number;
}

function ListTicketsByEmail({ event, attendanceId }: Props) {
    const [email, setEmail] = useState('');
    const [execute, result] = useLazyQuery(gql`
        query list_tickets_by_email($object: list_tickets_by_email_input!) {
            list_tickets_by_email(object: $object) {
                items
            }
        }
    `)
    const { control, handleSubmit, reset } = useForm<LoginFields>({
        resolver: yupResolver(schema),
        defaultValues: {

        }
    });

    const onSubmit: SubmitHandler<LoginFields> = async (data) => {
        try {
            setEmail(data.email);
            execute({
                variables: {
                    object: {
                        attendance_id: attendanceId,
                        event_id: event.id,
                        email: data.email,
                    },
                }
            })
        } catch (e) {
            // dialog.showError(e)
        } finally {
        }
    }

    const clear = () => {
        setEmail('');
        reset();
    }

    const attendees = (result.data?.list_tickets_by_email?.items || []) as AttendeeModel[];

    const checkins = useMemo(() => attendees.filter(a => a.dropins.length > 0 && !!a.dropins[0].checkin_at).length, [attendees])

    return (
        <div className="my-2 border-gray-100 border-2 p-4 mt-6 mb-0 rounded-lg shadow-2xl">
            {email ? <div>
                <p className='text-xl font-bold'>{event.title}</p>
                <div className='py-4 border-b-4 mb-4 flex items-center'>
                    <div className='flex-1 pr-2'>
                        <p className='text-lg'>{attendees.length} {pluralize('Ticket', attendees.length)} found.</p>
                        <p className='text-sm'>Please Check-In all guests who are here.</p>
                    </div>
                    <Button small onClick={clear} className='bg-gray-400 hover:bg-gray-600' label='Change Email' />
                </div>
                {checkins > 0 ? <div className='p-6 mb-4 rounded-lg bg-green-100'>
                    <h2 className='text-2xl text-center font-bold mb-4'>Show this screen to organizers to enter the venue.</h2>
                    <h2 className='text-4xl text-green-900 text-center mb-4'><span className='font-bold'>{checkins}</span> {pluralize('Check-In', checkins)}</h2>
                </div> : null}
                {attendees.map(attendee => <TicketItem
                    attendee={attendee}
                    attendance_id={attendanceId}
                    refresh={result.refetch}
                    key={attendee.id} />)}
                {attendees.length === 0 ? <p>No tickets found for <span className='text-lg py-4 font-bold text-red-700'>{email}</span>. Please 
                make sure that the email address is spelled correctly and was the one used to purchase tickets for this event.</p> : null}
                

            </div> : <form
                className='text-center'
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <EventHeader event={event} />

                <p className='text-center text-md mt-4 uppercase font-semibold text-green-700'>Self Check-In</p>
                <h2 className='text-center text-lg mb-4'>{event.title}</h2>

                {result.loading ? <Loading /> : null}
                <div
                    className="">
                    <p className='font-bold text-xl mb-2'>
                        Enter Email Address Used To Purchase Tickets For This Event
                    </p>
                    <TextInput
                        control={control}
                        type='email'
                        name='email'
                        placeholder='Email Address'
                        autoFocus
                    />
                    <Button
                        type='submit'
                        label='Search My Tickets'
                        fullWidth
                    />
                    {/* <p className='text-xs mt-2 text-red-700'>Please use the email address you used to purchase your ticket for this event.</p> */}
                </div>
            </form>}
        </div>
    )
}

export default ListTicketsByEmail